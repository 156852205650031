@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?pd8rne');
  src:  url('../font/icomoon.eot?pd8rne#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?pd8rne') format('truetype'),
    url('../font/icomoon.woff?pd8rne') format('woff'),
    url('../font/icomoon.svg?pd8rne#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-soutien-continu:before {
  content: "\e900";
}
.icomoon-service-apres-vente:before {
  content: "\e901";
}
.icomoon-pieces-accessoires:before {
  content: "\e902";
}
.icomoon-design-flexible:before {
  content: "\e903";
}

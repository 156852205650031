/* Background
-------------------------------------------------------------- */
.switcher-container h2 a,
.hentry .post-link a,
#sidebar .widget.widget_search .search-form .search-submit:hover,
.widget .widget-title span:after,
#sidebar .widget.widget_follow .follow-item:hover a,
#footer .widget.widget_tags .tags-list a:hover, .widget.widget_tags .tags-list a:hover,
#footer .widget.widget_tags .tags-list a:hover:before, .widget.widget_tags .tags-list a:hover:before,
.themesflat-pagination ul li .page-numbers:hover, .themesflat-pagination ul li .page-numbers.current,
.hentry .post-socials a:hover,
.hentry .post-tags a:hover,
.themesflat-pagination .page-prev-next > li a:hover:after,
button, input[type="button"], input[type="reset"], input[type="submit"],
#scroll-top:hover:before,
.post-media > .post-calendar .entry-calendar,
.mobile-button:before, .mobile-button:after, .mobile-button span,
.hentry .post-tags a:hover:before,
.themesflat-tabs.style-1 .tab-content ul span.fa,
.themesflat-testimonials .sep.has-width.accent-bg, .themesflat-headings .sep.has-width.accent-bg,
.header-style-2 #main-nav > ul > li > a:after,
.themesflat-icon-box.style-1.light-bg .icon-wrap:before,
.themesflat-button.bg-accent,
.themesflat-button.bg-white:hover:before,
.themesflat-image-box.has-icon:hover .icon-wrap,
.owl-theme .owl-nav [class*="owl-"],
.custom .tp-bullet:hover, .custom .tp-bullet.selected,
.custom.tparrows:hover,
.themesflat-progress.style-1 .progress-animate,
.themesflat-accordions.has-icon.iconstyle-2 .accordion-item.active .accordion-heading > .inner:before,
.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span,
.themesflat-team.style-1 .socials li a:hover,
.widget.widget_list .list-item > a:hover:before,
.widget.widget_lastest_twitter li:hover .icon-wrap.has-width.w30,
.comment-list.style-2 .comment-text .socials > a:hover,
.themesflat-accordions.style-4 .accordion-item.active .accordion-heading,
.themesflat-tabs.style-2 .tab-title .item-title.active,
.themesflat-button.bg-light-white:hover:before,
.flat-content-wrap .sep.has-width.accent-bg,
.themesflat-testimonials.has-width.w35 .icon-wrap,
.themesflat-price-table.style-1 .tag,
.themesflat-price-table .sep.has-width.accent-bg,
.has-effect-icon .elm-link a.icon-1:hover { background-color: $accentcolor; }

.bg-ffc { background-color: $accentcolor !important; }

/* Color
-------------------------------------------------------------- */
.header-style-1 #site-header #main-nav > ul > li.current-menu-item > a, 
.header-style-1 #site-header #main-nav > ul > li:hover > a,
a:hover, a:focus, a:active,
#featured-title #breadcrumbs a:hover,
.hentry .post-meta a:hover,
#footer-widgets .widget.widget_text span.fa,
#bottom ul.bottom-nav > li.current-menu-item > a, #bottom ul.bottom-nav > li > a:hover,
#main-nav .sub-menu li a:hover,
.has-effect-icon .elm-link a.icon-2:hover, .has-effect-icon .elm-link span.icon-3:hover,
.themesflat-pagination .page-prev-next > li a:hover,
.themesflat-pagination .page-prev-next > li a:hover,
.comment-list .comment-reply a,
.comment-list .comment-reply:after,
#main-nav-mobi ul > li.current-menu-item > a, #main-nav-mobi ul > li > a:hover,
.themesflat-headings .sep.has-icon .icon-wrap > i,
.themesflat-icon-box.accent-color .icon-wrap,
.themesflat-icon-box.style-3 .more-link a:hover,
.themesflat-list.style-1 .icon,
.themesflat-button.bg-white,
.themesflat-project.style-1 .elm-meta a,
.themesflat-testimonials.has-width.w100 .icon-wrap,
.themesflat-accordions.has-icon.iconstyle-1 .accordion-item.active .accordion-heading > .inner:before,
.header-style-3 #site-header #main-nav > ul > li.current-menu-item > a, .header-style-3 #site-header #main-nav > ul > li:hover > a,
.themesflat-image-box.style-2 .elm-readmore a,
.themesflat-filter.style-1 li a:hover, .themesflat-filter.style-1 li.active a,
.themesflat-project.style-2 .elm-meta a:hover,
.themesflat-testimonials .list-star i,
.themesflat-counter.style-1 .suffix,
.widget.widget_list .list-item > a:hover,
.widget.widget_lastest_twitter .text a:hover,
.detail-inner-wrap .list-info span > a:hover,
.themesflat-price-table.style-1 .price-table-price .figure,
.themesflat-price-table.style-1 .price-table-price .currency,
#top-bar .top-bar-socials .icons a:hover { color: $accentcolor; }

.text-accent { color: $accentcolor !important;  }
/* Border
-------------------------------------------------------------- */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.has-effect-icon .elm-link a.icon-1:hover,
.themesflat-pagination ul li .page-numbers:hover, .themesflat-pagination ul li .page-numbers.current,
.hentry .post-tags a:hover,
.hentry .post-tags a:hover:before,
.themesflat-contact-form input[type="text"]:focus, .themesflat-contact-form input[type="email"]:focus,
.themesflat-accordions.has-icon.iconstyle-2 .accordion-item.active .accordion-heading > .inner:before,
.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span,
.themesflat-accordions.style-4 .accordion-item.active .accordion-heading,
.has-thumb.w185 .owl-thumbs .owl-thumb-item.active,
#footer .widget.widget_tags .tags-list a:hover:before, .widget.widget_tags .tags-list a:hover:before { border-color: $accentcolor; }

.animsition-loading:after { border-color: $accentcolor transparent transparent transparent; }


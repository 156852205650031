.bg-extra-dark-gray {
    background-color: #1c1c1c;
}

.opacity1 {opacity:.1}
.opacity2 {opacity:.2}
.opacity3 {opacity:.3}
.opacity4 {opacity:.4}
.opacity5 {opacity:.5}
.opacity6 {opacity:.6}
.opacity7 {opacity:.7}
.opacity8 {opacity:.8}
.opacity9 {opacity:.9}

div.overlayed {
	z-index: 0;
	position: relative;
	div, p, span, h1, h2, h3, h4, h5, h6 {
		z-index: 10;
		position: relative;
	}
	div.black-overlay {
		position: absolute;
		background-color: rgba(0,0,0,0.6);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5
	}
	/* opacity */
	.opacity-very-light {position: absolute; height: 100%; width: 100%; opacity: 0.2; top:0; left: 0;}
	.opacity-light {position: absolute; height: 100%; width: 100%; opacity: 0.3; top:0; left: 0;}
	.opacity-extra-medium {position: absolute; height: 100%; width: 100%; opacity: 0.5; top:0; left: 0;}
	.opacity-medium {position: absolute; height: 100%; width: 100%; opacity: 0.75; top:0; left: 0;}
	.opacity-full {position: absolute; height: 100%; width: 100%; opacity: 0.8; top:0; left: 0;}
	.opacity-full-dark {position: absolute; height: 100%; width: 100%; opacity: 0.9; top:0; left: 0;}
}



.not-loaded {
	position: relative;
	#slider-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 999;
	}
}
.not-loaded {
	filter: blur(20px);
	transform: scale(1);
	-webkit-transition: filter 0.3s linear;
	-moz-transition: filter 0.3s linear;
	-ms-transition: filter 0.3s linear;
	-o-transition: filter 0.3s linear;
	transition: filter 0.3s linear;
	&.loaded {
		filter: blur(0px);
	}
}
sup {
	top: -1em;
	font-size: 50%;
}


/********** CTA **********/

/********** HEADER **********/
#site-logo {
	margin-top: -20px;
	margin-bottom: -20px;
}

/********* FOOTER *********/
.footer-img {
	margin-bottom: 20px;
	img {
		
	}
}
#footer-widgets .widget.widget_text {
	background: none !important;
}

/********* HOME **********/
.themesflat-icon-box.style-2 .elm-readmore a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: $accentcolor;
    display: inline-block;
    margin-top: 10px;
    position: relative;
}

.themesflat-icon-box.style-2 .elm-readmore a:hover {
    transform: translateX(10px);
}

.akia-full-widget {
	height: 100%;
}

.themesflat-icon-box.padding-inner {
	padding: 38px 30px 29px;
}

.rev_slider .slotholder {
	position: relative;
	&:after {
	    width: 100%;
	    height: 100%;
	    content: "";
	    position: absolute;
	    left: 0;
	    top: 0;
	    pointer-events: none;
	 
	    /* black overlay with 50% transparency */
	    background: rgba(0, 0, 0, 0.3);
	}
}

#akia-home-slider {
    padding-top: 42%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    .content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 80px;
        margin-right: 80px;
        .subtitle {
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 30px;
            font-weight: 600;
            color: white;
        }
        .title {
            font-size: 40px;
            line-height: 1.25;
            color: white;
        }
        &.desktop {
            display: block !important;
        }
        &.mobile {
            display: none !important;
        }
    }
}

.themesflat-row .span_1_of_6 {
	&.span_1_of_7 {
		width: 55%;
	}
	&.span_1_of_5 {
		width: 45%;
	}
}
.icomoon-design-flexible {
	font-size: 90%;
}
.icomoon-soutien-continu {
	font-size: 110%;
}
#support {
	background-image: url('/images/ezkit-support-black.jpg');
	h2.heading {
		color: white;
	}
	.sep-solid {
		border-color: white;
	}
}
#book-an-appointment {
	background-image: url('/images/ezkit-book-appointment-2.jpg');
	background-repeat: no-repeat;
}

/******** CONTACT ********/
.themesflat-headings.style-1 .heading.akia-contact {
	font-size: 24px;
	line-height: 34px;
}
.akia-merci {
	a {
		font-weight: 700;
		font-size: 1.2em;
		text-decoration: underline;
	}
}
.themesflat-gallery.style-1 .text-wrap {
	background-color: transparent;
	padding: 20px
}

/******** MODAL **********/

.triggering-modal-wrapper {
	display: block;
    width: 400px;
    height: 62px;
    position: fixed;
    bottom: 80px;
    right: 0;
    padding: 10px;
    cursor: pointer;
    animation: all 2s linear;
    z-index: 20;
    opacity: 1 !important;
    background-color: transparent;
    &.hidden {
		display: none !important;
	}
    .triggering-modal {
    	position: relative;
	    padding: 15px;
	    background-color: $accentcolor;
	    border-radius: 4px;
	    p {
	    	margin-bottom: 5px !important;
	    	color: white;
	    	max-width: 75%;
	    	font-size: 13px;
	    	i {
	    		margin-left: 10px;
	    	}
	    }
	   	.modal-heading {
    		font-weight: 600;
    		text-transform: uppercase;
	   	}
	   	.modal-avatar {
	   		max-width: 70px;
		    position: absolute;
		    top: 50%;
		    right: 20px;
		    transform: translateY(-50%);
	   	}
	   	.close-btn {
	   		&:before {
	   			content: '';
			    width: 15px;
			    height: 15px;
			    position: absolute;
			    top: 12.5px;
			    right: 12.5px;
			    background-size: contain;
			    opacity: .3;
	   			background-image: url('/images/Icon-Close-Light.png');
    			opacity: .6;
    			-webkit-transition: 0.3s opacity;
    			-moz-transition: 0.3s opacity;
    			-ms-transition: 0.3s opacity;
    			-o-transition: 0.3s opacity;
    			transition: 0.3s opacity;
	   		}
	   		&:hover {
	   			opacity: 1;
	   		}
	   	}
    }
}

.modal-open .triggering-modal-wrapper {
    display: none !important;
}

#bite-28 { 
    .modal-content {
        background-color: $accentcolor;
    }
    .modal-header {
        padding: 26px 31px;
    }
    .modal-body {
        border-top: 1px solid rgba(255,255,255,0.2);
        padding: 23px 31px 27px;
    }
    .popup-title {
        font-size: 16px;
        line-height: 1.7;
        margin-bottom: -1px;
        letter-spacing: 0.1px;
    }
    .modal-header p {
        font-size: 14px;
        line-height: 1.86;
    }
    .modal-body p {
        font-size: 16px;
        line-height: 1.5;
        a {
        	color: white !important;
        	text-decoration: underline;
        }
    }
    .form-control {
        background-color: rgba(255, 255, 255, 0.2);
    }
    input.form-control {
        height: 43px;
    }
    textarea.form-control {
        padding: 12px;
        height: 76px;
    } 
    .btn {
        background-color: #1565c0;
        font-size: 16px;
        line-height: 1.5;
        height: 43px;
    }
    .btn:hover {
        background-color: #217ee7;
    }
    .btn:active {
        background-color: #104d92;
    }
    .modal-checkbox {
        font-size: 12px;
        line-height: 1.4;
        color: white;
        input {
            margin-right: 10px !important;
        }
    }
}
@media only screen and (max-width: 767px) {
    #bite-28 .modal-content {
        text-align: center;
    }
    #bite-28 .col-md-auto {
        margin-bottom: 20px;
    }
    .triggering-modal-wrapper {
    	display: none !important;
    }
}

#bite-33 {
    .modal-content {
        background: rgba(68,68,68,1);
        background: -moz-linear-gradient(219deg, rgba(68,68,68,1) 0%, rgba(43,43,43,1) 100%);
        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(68,68,68,1)), color-stop(100%, rgba(43,43,43,1)));
        background: -webkit-linear-gradient(219deg, rgba(68,68,68,1) 0%, rgba(43,43,43,1) 100%);
        background: -o-linear-gradient(219deg, rgba(68,68,68,1) 0%, rgba(43,43,43,1) 100%);
        background: -ms-linear-gradient(219deg, rgba(68,68,68,1) 0%, rgba(43,43,43,1) 100%);
        background: linear-gradient(219deg, rgba(68,68,68,1) 0%, rgba(43,43,43,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#2b2b2b', GradientType=1 );
    }
    .popup-inner {
        padding: 38px 37px 25px;
    }
    .popup-image-inner {
        background-color: white;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    // .popup-image-inner:after {
    //     content: '';
    //     background-color: rgba(254, 79, 79, 0.4);
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100%;
    //     border-top-left-radius: 10px !important;
    //     border-bottom-left-radius: 10px !important;
    // }
    .popup-image-inner img {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        height: 80%;
        margin: 12% auto;
        // height: 60%;
        // margin: 30% auto;
    }   
    .popup-title {
        font-size: 27px;
        line-height: 1.41;
        letter-spacing: -0.4px;
    }
    .popup-text {
        opacity: 0.5;
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.1px;
        margin-bottom: 30px;
    }
    .form-control {
        background-color: rgba(255,255,255,0.1);
        height: 43px;
        padding-left: 17px;
        padding-right: 17px;
    }
    .form-control::-moz-placeholder {
        line-height: 2.1;
    }
    .form-control:-moz-placeholder {
        line-height: 2.1;
    }
    .btn-subscribe {
        background-color: #1565c0;
        height: 43px;
        font-size: 14px;
        line-height: 1.71;
    }
    .btn-subscribe:hover {
        background-color: #217ee7;
    }
    .btn-subscribe:active {
        background-color: #104d92;
    }
    .btn-link {
        opacity: 0.5;
        font-size: 14px;
        line-height: 1.86;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -ms-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out;
    }
    .btn-link:hover {
        text-decoration: none;
        opacity: 1;
    }
}
@media only screen and (max-width: 767px) {
    #bite-33 .col-md-auto {
        display: none;
    }
}
@media only screen and (max-width: 575px) {
    #bite-33 .popup-inner {
        padding: 38px 45px 25px;
    }
}

/******** RESPONSIVE ********/

@media only screen and (max-width: 991px) {
	.themesflat-row .span_1_of_6 {
		&.span_1_of_7 {
			width: 100%;
		}
		&.span_1_of_5 {
			width: 100%;
		}
	}
	.special-akia-column {
		margin-bottom: 20px;
	}
	.themesflat-gallery.style-1 .text-wrap {
		left: 0px;
		span {
			font-size: 18px;
			line-height: 24px
		}
		.heading {
			font-size: 24px;
			line-height: 30px;
		}
	}
    #akia-home-slider {
        .content {
            .title {
                font-size: 34px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
	.themesflat-gallery.style-1 .text-wrap {
		left: 20px;		
	}
	#support {
		background-image: none;
		h2.heading {
			color: black;
		}
		.sep-solid {
			border-color: black;
		}
	}
	#book-an-appointment {
		background-attachment: initial;
    	background-position: center 20%;
	}
	#contact-back-home {
		background-image: url('/images/ezkit-contact.jpg');
	    height: 300px;
	    background-size: cover;
	    background-position: center center;
	    img {
	    	display: none !important;
	    }
	}
	.col.span_1_of_4 {
		flex-basis: 100%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
	}
    #akia-home-slider {
        padding-top: 38%;
        .content {
            left: 30px;
            margin-right: 30px;
            .subtitle {
                font-size: 16px;
                margin-bottom: 15px;
            }
            .title {
                font-size: 26px;
            }
        }
    }
    h2.heading, .heading.font-size-30 {
        font-size: 23px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width: 479px) {
	.themesflat-gallery.style-1 .text-wrap {
		display: block !important;
	}
    #akia-home-slider {
        padding-top: 55%;
        .content {
            .subtitle {
                margin-bottom: 10px;
            }
            .title {
                font-size: 24px;
            }
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: block !important;
            }
        }
    }
    h2.heading, .heading.font-size-30 {
        font-size: 20px !important;
        line-height: 28px !important;
    }
}
@media only screen and (max-width: 400px) {
	#akia-home-slider {
        .content {
            .title {
                font-size: 22px;
            }
        }
    }
}

@media only screen and (max-width: 300px) {
    #akia-home-slider {
        padding-top: 70%;
        .content {
            left: 10px;
            margin-right: 10px;
            .title {
                font-size: 21px;
            }
        }
    }
}

@media only screen and (min-width: 1921px) {
	
}

@media only screen and (min-width: 992px) {
	.special-akia-column {
		padding-left: 0 !important;
		padding-right: 0 !important;
		.akia-placing-inner {
			position: relative;
			.inner {
				position: absolute;
				top: 50%;
				left: auto;
				transform: translate(0, -50%);
			}
		}
	}
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 660px) {
	.akia-full-height-col {
		height: 100%;
	}
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	
}
@media only screen and (min-width: 992px) and (max-width: 1239px) {
	
}


@font-face {
  font-family: 'autoraicon';
  src:  url('../font/autoraicon.eot?77c5li');
  src:  url('../font/autoraicon.eot?77c5li#iefix') format('embedded-opentype'),
    url('../font/autoraicon.ttf?77c5li') format('truetype'),
    url('../font/autoraicon.woff?77c5li') format('woff'),
    url('../font/autoraicon.svg?77c5li#autoraicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="autora-icon-"], [class*=" autora-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'autoraicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.autora-icon-author:before {
  content: "\e900";
}
.autora-icon-author-outline:before {
  content: "\e901";
}
.autora-icon-build:before {
  content: "\e902";
}
.autora-icon-build-home:before {
  content: "\e903";
}
.autora-icon-building-outline:before {
  content: "\e904";
}
.autora-icon-camera-outline:before {
  content: "\e905";
}
.autora-icon-check-circle-outline:before {
  content: "\e906";
}
.autora-icon-concrete:before {
  content: "\e907";
}
.autora-icon-facebook:before {
  content: "\e908";
}
.autora-icon-hat-outline:before {
  content: "\e909";
}
.autora-icon-long-arrow-right:before {
  content: "\e90a";
}
.autora-icon-phone-contact:before {
  content: "\e90b";
}
.autora-icon-quality:before {
  content: "\e90c";
}
.autora-icon-time:before {
  content: "\e90d";
}
.autora-icon-twitter:before {
  content: "\e90e";
}

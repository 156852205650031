/* Woocommerce
-------------------------------------------------------------- */
.woocommerce-page .content-woocommerce .woocommerce-result-count { position: relative; margin: 0; float: left; font-size: 14px; font-family: "Poppins", sans-serif;  }
.woocommerce-page .content-woocommerce .woocommerce-result-count:after { content: ""; position: absolute; left: 0; bottom: 0; width: 100%; height: 1px; background-color: #e0e0e0; }
.woocommerce-page .content-woocommerce .woocommerce-ordering { float: right; position: relative; }
.woocommerce-page .content-woocommerce .woocommerce-ordering:before { content: "SORT BY:"; font-family: "Poppins", sans-serif; position: absolute; left: -77px; top: 3px; font-size: 14px; }
.woocommerce-page .content-woocommerce .woocommerce-ordering select { border: 0; font-family: "Poppins", sans-serif; text-transform: uppercase; color: #999; background-color: #ebebeb; margin: 0; padding: 6px 20px; width: 265px; cursor: pointer; color: #999; font-size: 13px; }
.woocommerce-page .content-woocommerce .woocommerce-ordering select:focus { outline: 0; }
.woocommerce-page .content-woocommerce .products { padding: 36px 0 0 0; margin: 0 -17.5px; clear: both; list-style: none; }
.woocommerce-page .content-woocommerce .star-rating { width: 85px; height: 17px; background: url(../img/star.png) repeat-x left 0; }
.woocommerce-page .content-woocommerce .star-rating span { background: url(../img/star.png) repeat-x left -34px; height: 0; padding-top: 17px; overflow: hidden; float: left; }
.woocommerce-page .content-woocommerce p.stars { overflow: hidden; zoom: 1; }
.woocommerce-page .content-woocommerce p.stars span { width: 85px; height: 15px; position: relative; float: left; background: url(../img/star.png) repeat-x left 0; }
.woocommerce-page .content-woocommerce p.stars span a { transition: none; float: left; position: absolute; left: 0; top: 0; width: 17px; height: 0; padding-top: 17px; overflow: hidden; }
.woocommerce-page .content-woocommerce p.stars span a:hover, p.stars span a:focus { background: url(../img/star.png) repeat-x left -17px; }
.woocommerce-page .content-woocommerce p.stars a.active { background: url(../img/star.png) repeat-x left -34px; }
.woocommerce-page .content-woocommerce p.stars span a.star-1 { width: 17px; z-index: 10; }
.woocommerce-page .content-woocommerce p.stars span a.star-2 { width: 34px; z-index: 9; }
.woocommerce-page .content-woocommerce p.stars span a.star-3 { width: 51px; z-index: 8; }
.woocommerce-page .content-woocommerce p.stars span a.star-4 { width: 68px; z-index: 7; }
.woocommerce-page .content-woocommerce p.stars span a.star-5 { width: 85px; z-index: 6; }
.woocommerce-page .star-rating > span.two { width: 20%; }
.woocommerce-page .star-rating > span.four { width: 40%; }
.woocommerce-page .star-rating > span.six { width: 60%; }
.woocommerce-page .star-rating > span.eight { width: 80%; }
.woocommerce-page abbr { border: none; text-decoration: none }
.woocommerce-page div.product div.images img { display: block; width: 100%; height: auto; box-shadow: none; }
.woocommerce-page .return-to-shop .button { padding: 8px 30px; }
.woocommerce-page .woocommerce-MyAccount-content .woocommerce-info .button { background-color: transparent; color: #1c63b8; }
.woocommerce-page .woo-single-post-class .summary .stock.in-stock { display: none; }
.product .onsale { font-family: "Poppins", sans-serif; position: absolute; right: 10px; top: 10px; z-index: 999; font-size: 12px; letter-spacing: 0.5px; line-height: 25px; padding: 0 24px; color: #fff; text-transform: uppercase; background-color: #1c63b8; display: inline-block; }
.products li .product-info .star-rating { display: none; }

/* Buttons */
.woocommerce-page .button { border-radius: 3px; font-weight: 500; letter-spacing: 0.5px; text-align: center; display: inline-block; background-color: #333; color: #fff; text-transform: uppercase; }
.woocommerce-page .button:hover { opacity: 0.7; }

/* Product Item */
.woocommerce-page.shop-col-2 .products li { width: 50%; }
.woocommerce-page.shop-col-3 .products li { width: 33.3333333%; }
.woocommerce-page.shop-col-4 .products li { width: 25%; }
.woocommerce-page.shop-col-5 .products li { width: 20%; }

.woocommerce-page.shop-col-2 .products li { width: 50%; }
.woocommerce-page.shop-col-3 .products li { width: 33.3333333%; }
.woocommerce-page.shop-col-4 .products li { width: 25%; }
.woocommerce-page.shop-col-5 .products li { width: 20%; }

.products li { float: left; padding: 0 17.5px; margin-bottom: 45px; }
.sidebar-left.woocommerce-page.shop-col-3 .products li,
.sidebar-left.woocommerce-page.shop-col-4 .products li,
.sidebar-left.woocommerce-page.shop-col-5 .products li { padding: 0 10px; }

.products li.first { clear: both; }
.products li h2 { margin-bottom: 0; font-size: 15px; text-transform: uppercase; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; }
.products li .short-desc > p { margin: 0; }

.products li .product-info { text-align: center; padding: 24px 0 0; background-color: #fff; position: relative; }
.products li .product-info .add_to_cart_button,
.products li .product-info .product_type_variable,
.products li .product-info .added_to_cart { position: absolute; left: 0; top: -35px; opacity: 0; width: 100%; line-height: 35px; background-color: #dcdcdc; color: #333; font-family: "Poppins", sans-serif; font-size: 13px; letter-spacing: 0.5px; text-transform: uppercase; border-radius: 0; display: inline-block; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; }

.products li:hover .product-info .add_to_cart_button,
.products li:hover .product-info .product_type_variable,
.products li .product-info .added_to_cart { opacity: 1; }

.products li .product-thumbnail { position: relative; overflow: hidden; }
.products li .product-thumbnail img { width: 100%; height: auto; }
.products li .price { font-size: 20px; font-family: "Poppins", sans-serif; color: #1c63b8; display: block; margin: 0; }
.products li .price del { color: #b7b7b7; padding-right: 6px; }
.products li .price ins { background-color: none; }
.products li h2:hover { color: #1c63b8; }
.products li .product-info .add_to_cart_button:hover,
.products li .added_to_cart:hover { background-color: #1c63b8; color: #fff; }

/* Single Product
-------------------------------------------------------------- */
.woo-single-post-class { position: relative; }
.woo-single-post-class .images { width: 48.1%; float: left; position: relative; }
.woo-single-post-class .images .woocommerce-product-gallery__trigger { width: 37px; height: 39px; z-index: 999; position: absolute; right: 17px; top: 12px; text-align: center; }
.woo-single-post-class .images .woocommerce-product-gallery__trigger:after { width: 37px; height: 39px; line-height: 39px; background-color: rgba(0,0,0,0.5); color: #fff; text-align: center; content: "\e957"; font-family: "wprticons"; position: absolute; left:0; top: 0;  }
.woo-single-post-class .images .woocommerce-product-gallery__trigger:hover:after { opacity: 0.7; }
.woo-single-post-class .images .woocommerce-product-gallery__trigger img { display: none !important; }
.woo-single-post-class .images .flex-control-thumbs { margin: 10px -5px 0; width: auto; }
.woo-single-post-class .images .flex-control-thumbs li { padding: 0 5px 10px; width: 16.667%; }
.woo-single-post-class .summary { width: 48.1%;	float: right; }
.woo-single-post-class .summary h1 { font-size: 28px; text-transform: uppercase; margin: -2px 0 10px 0; }
.woo-single-post-class .summary .woocommerce-product-rating { position: relative; margin-bottom: 14px; }
.woo-single-post-class .summary .woocommerce-review-link { display: none; color: #999; font-size: 13px; letter-spacing: 1px; text-transform: uppercase; position: absolute; left: 107px; top: 0; line-height: 20px; }

.woo-single-post-class .summary .price { font-size: 26px; font-family: "Poppins", sans-serif; color: #1c63b8; line-height: normal; padding-bottom: 22px; margin: 0 0 26px; border-bottom: 1px solid #eee; }
.woo-single-post-class .summary .price del { font-size: 26px; color: #b7b7b7; padding-right: 2px; }
.woo-single-post-class .summary .price ins { background-color: none; }

.woo-single-post-class .summary .cart table,
.woo-single-post-class .summary .cart tr,
.woo-single-post-class .summary .cart td { border: 0; padding: 0; }
.woo-single-post-class .summary .cart table .label { width: 85px; font-family: "Poppins", sans-serif; color: #333; text-transform: uppercase; }
.woo-single-post-class .summary .cart { margin-top: 37px; }
.woo-single-post-class .summary .cart .quantity { display: inline-block; float: left; }
.woo-single-post-class .summary .cart .quantity input { border: 1px solid #9b9b9b; width: 57px; margin: 0 16px 0 0; text-align: center; background-color: #fff; padding: 10px; }
.woo-single-post-class .summary .cart .single_add_to_cart_button { padding: 17px 67px 17px 95px; font-size: 14px; line-height: 16px; position: relative; }
.woo-single-post-class .summary .cart .single_add_to_cart_button:hover { background-color: #333; }
.woo-single-post-class .summary .cart .single_add_to_cart_button:before { content: "\e90b"; font-family: "wprticons"; position: absolute; left: 66px; top: 12px; line-height: 25px; font-size: 18px; }
.woo-single-post-class .summary .cart table { position: relative; }
.woo-single-post-class .summary .cart .reset_variations { position: absolute; right: 0; bottom: -20px; }
.woo-single-post-class .woocommerce-tabs .panel .shop_attributes p { margin-bottom: 5px; }

.woo-single-post-class .summary .product_meta { clear: left; padding: 17px 0 0; margin-top: 38px; border-top: 1px solid #eee; }
.woo-single-post-class .summary .product_meta > span { margin-left: 40px; font-family: "Poppins", sans-serif; font-size: 13px; display: inline-block; color: #999; text-transform: uppercase; }
.woo-single-post-class .summary .product_meta > span:first-child { margin: 0; }
.woo-single-post-class .summary .product_meta > span a { font-family: "Open Sans", sans-serif; font-size: 14px; color: #333; }
.woo-single-post-class .summary .product_meta > span a:hover { text-decoration: underline; }
.woo-single-post-class .summary .product_meta > span a:first-child { padding-left: 10px; }

.woo-single-post-class .woocommerce-tabs { clear: both; padding-top: 60px; }

/* Related Products */
.woo-single-post-class .related.products { padding: 0; margin: 0; padding-top: 30px; margin-top: 30px; border-top: 1px solid #eee;; }
.woo-single-post-class .related.products .products:before,
.woo-single-post-class .related.products .products:after { content: ""; display: table; clear: both; }
.woo-single-post-class .related.products > h2 { padding-bottom: 11px; position: relative; margin-bottom: 27px; font-size: 14px; color: #333; font-family: "Poppins", sans-serif; text-transform: uppercase; }
.woo-single-post-class .related.products .products { margin: 0 -10px; padding-top: 0; }
.woo-single-post-class .related.products .products li { width: 25%; float: left; margin-bottom: 0; padding: 0 10px; }
.woo-single-post-class .related.products .products li .product-info { padding: 17px 0 0; }
.woo-single-post-class .related.products .products li h2 { font-size: 14px; margin-bottom: 5px; }
.woo-single-post-class .related.products > h2:after { width: 57px; height: 2px; background-color: #dbdbdb; content: ""; position: absolute; left: 0; top: 100%; }
.woo-single-post-class .related.products .products li .product-info .add_to_cart_button { display: none; }
.woo-single-post-class .related.products .products li .price { font-size: 18px; }

/* Cart Page
-------------------------------------------------------------- */
.woocommerce-page .shop_table.cart th { font-family: "Poppins", sans-serif; color: #333; font-weight: 400; text-transform: uppercase; }
.woocommerce-page .shop_table.cart td,
.woocommerce-page .shop_table.cart th { padding: 20px; vertical-align: middle; }

.woocommerce-page .shop_table.cart .product-remove { width: 80px; }
.woocommerce-page .shop_table.cart .product-thumbnail { width: 180px; }
.woocommerce-page .shop_table.cart .product-name { width: 280px; }

.woocommerce-page .shop_table.cart .product-name a { font-family: "Poppins", sans-serif; color: #333; }
.woocommerce-page .shop_table.cart .product-name a:hover { color: #1c63b8; }
.woocommerce-page .shop_table.cart .product-remove a { display: inline-block; color: #999 !important; background-color: #f7f7f7; width: 20px; height: 20px; line-height: 20px; margin: 0; padding: 0; font-size: 16px; text-align: center; -webkit-transition: all 0.3s; transition: all 0.3s; }
.woocommerce-page .shop_table.cart .product-remove a:hover { color: #333 !important; }

.woocommerce-page .shop_table.cart .cart_item .product-thumbnail img { width: 80px; }
.woocommerce-page .shop_table.cart .cart_item .product-quantity input[type='number'] { width: 60px; height: 50px; padding-right: 5px; margin: 0; background-color: transparent; }

.woocommerce-page .shop_table.cart .coupon { float: left; }
.woocommerce-page .shop_table.cart .coupon > label { display: none; }
.woocommerce-page .shop_table.cart .coupon #coupon_code { background-color: #f5f5f5; border: 0; width: 200px; display: inline-block; margin: 5px 5px 0 0; display: inline-block; padding: 8px 12px; }
.woocommerce-page .shop_table.cart .coupon > input[type='submit'] { font-size: 12px; margin-right: 8px; }
.woocommerce-page .shop_table.cart .coupon + input[type='submit'] { font-size: 12px; margin-top: 5px; }

.woocommerce-page .cart_totals { width: 60%; margin-top: 56px; }
.woocommerce-page .cart_totals > h2 { font-size: 20px; margin-bottom: 20px; }
.woocommerce-page .cart_totals .shop_table { border: 2px solid #e4e4e4; margin-bottom: 20px;}
.woocommerce-page .cart_totals .shop_table th,
.woocommerce-page .cart_totals .shop_table td { border-color: #e4e4e4; }
.woocommerce-page .cart_totals .shop_table .order-total th,
.woocommerce-page .cart_totals .shop_table .order-total td { border-bottom: 0; }
.woocommerce-page .cart_totals .shop_table td { border-right: 0; padding: 10px 27px; }
.woocommerce-page .cart_totals .shop_table th { color: #333; padding: 10px 30px; }
.woocommerce-page .cart_totals .shop_table .cart-subtotal td,
.woocommerce-page .cart_totals .shop_table .order-total td,
.woocommerce-page .cart_totals .shop_table .tax-rate td { text-align: right; color: #333; }
.woocommerce-page .cart_totals p,
.woocommerce-page .cart_totals .woocommerce-shipping-calculator select { margin: 0 0 5px; }
.woocommerce-page .cart_totals .shipping-calculator-form button[type='submit'] { width: 100%; }
.woocommerce-page .wc-proceed-to-checkout .button { font-size: 16px; letter-spacing: 1px; background-color: #1c63b8; padding: 10px 30px; width: 100%; }

/* Checkout Page
-------------------------------------------------------------- */
.woocommerce-page .woocommerce-checkout { clear: both; }
.woocommerce-page .login input { margin-bottom: 0; }
.woocommerce-page form .form-row { margin: 0 0 20px; }
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last { float: left; width: 48%; overflow: visible; }
.woocommerce-page form .form-row input.input-text,
.woocommerce-page form .form-row textarea { background-color: #fafafa; border: 1px solid #dcdcdc; width: 100%; margin: 0; outline: 0; line-height: 24px; }
.woocommerce-billing-fields .select2-container--default .select2-selection--single,
.woocommerce-shipping-fields .select2-container--default .select2-selection--single { display: block; border-radius: 0; line-height: 46px; height: 46px; background-color: #fafafa; border: 1px solid #dcdcdc; }
.woocommerce-billing-fields .select2-selection--single .select2-selection__rendered { padding: 8px 20px; color: #777; }
.woocommerce-billing-fields .select2-container--default .select2-selection--single .select2-selection__arrow { top: 5px; right: 4px; }

.woocommerce-page form.checkout_coupon #coupon_code,
.woocommerce-page form.lost_reset_password .form-row input.input-text { padding: 9px 12px; margin-bottom: 30px; }
.woocommerce-page form .form-row label.inline { display: inline; }
.woocommerce-page form .form-row label.inline input { margin: 0 5px 0 10px; width: auto; }
.woocommerce-page form.lost_reset_password .woocommerce-Button.button { margin: 25px 0 0 10px; }
.woocommerce-page .woocommerce-shipping-fields,
.woocommerce-page #order_review_heading { clear: both; }
.woocommerce-billing-fields .create-account > label { margin: 0; display: inline-block; }
.woocommerce-page .woocommerce-lost-password .form-row-first { float: none; }
.woocommerce-page #ship-to-different-address > label { padding-right: 5px; }
.woocommerce-page .woocommerce-billing-fields h3,
.woocommerce-page .woocommerce-shipping-fields h3,
.woocommerce-page #order_review_heading,
.woocommerce-page .woocommerce-additional-fields h3 { font-size: 20px; padding-top: 30px; }
.woocommerce-page .woocommerce-additional-fields .notes > label { display: none; }
.woocommerce-page form .form-row.create-account #createaccount { width: auto; margin-right: 5px; }
.woocommerce-page form .form-row.create-account + .create-account > p:first-child { margin-bottom: 10px; }

.woocommerce-checkout-review-order table th { background-color: #f5f5f5; font-family: "Poppins", sans-serif; color: #333; padding: 10px 25px; }
.woocommerce-checkout-review-order table td { padding: 10px 25px; }
.woocommerce-checkout-review-order .cart-subtotal td,
.woocommerce-checkout-review-order .order-total { font-family: "Poppins", sans-serif; font-size: 16px; color: #333; }
.woocommerce-checkout #payment ul.payment_methods { list-style: none; margin: 0; font-family: "Poppins", sans-serif; }
.woocommerce-checkout #payment ul.payment_methods li label { display: inline-block; padding-left: 5px; }
.woocommerce-checkout #payment .about_paypal { padding-left: 10px; }
.woocommerce-page .myaccount_user + h2,
.woocommerce-page .addresses h3,
.woocommerce-page #customer_login h2 { margin-bottom: 0; font-size: 18px; }
.woocommerce-page .register input { margin-bottom: 0; }
.woocommerce-page .woocommerce-MyAccount-content legend { border: 0; font-size: 18px; color: #333; padding-top: 20px; margin: 0; }

.woocommerce-page .form-row:before,.woocommerce ul.cart_list li:after,
.woocommerce-page  ul.cart_list li:before,
.woocommerce-page ul.product_list_widget li:after,
.woocommerce-page  ul.product_list_widget li:before,
.woocommerce-page .cart-collaterals .shipping_calculator:after,
.woocommerce-page .cart-collaterals .shipping_calculator:before,
.woocommerce-page .cart-collaterals:after,
.woocommerce-page .cart-collaterals:before,
.woocommerce-page form .form-row:after,
.woocommerce-page form .form-row:before,
.woocommerce-page ul.cart_list li:after,
.woocommerce-page ul.cart_list li:before,
.woocommerce-page ul.product_list_widget li:after,
.woocommerce-page ul.product_list_widget li:before { content:" "; display:table }

.woocommerce-page .cart-collaterals { width:100% }
.woocommerce-page .cart-collaterals .related { width:30.75%; float:left}
.woocommerce-page .cart-collaterals .cross-sells { width:48%; float:left }
.woocommerce-page .cart-collaterals .cross-sells ul.products{ float:none }
.woocommerce .cart-collaterals .cross-sells ul.products li,
.woocommerce-page .cart-collaterals .cross-sells ul.products li{ width:48% }

.woocommerce .cart-collaterals .shipping_calculator:after,
.woocommerce form .form-row-wide,
.woocommerce form .form-row:after,
.woocommerce ul.cart_list li:after,
.woocommerce ul.product_list_widget li:after,
.woocommerce-page .cart-collaterals .shipping_calculator:after,

.woocommerce-page form .form-row-wide,
.woocommerce-page form .form-row:after,
.woocommerce-page ul.cart_list li:after,
.woocommerce-page ul.product_list_widget li:after,
.woocommerce-page #order_comments_field {clear:both}

.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 { width:48% }
.woocommerce form .form-row label,.woocommerce-page form .form-row label { display:block; font-family: "Poppins", sans-serif; }
.woocommerce form .form-row label.checkbox,
.woocommerce-page form .form-row label.checkbox { display:inline }
.woocommerce-page form .form-row select,
.woocommerce-page form .form-row .input-text { width:100% }
.woocommerce-page form .form-row-last { float:right }
.woocommerce-page #payment .terms,
.woocommerce-page #payment .wc-terms-and-conditions { text-align:left; padding:0 1em 0 0; float:left }
.woocommerce-page #payment #place_order { font-size: 16px; width: 300px; background-color: #1c63b8; padding: 17px 0; margin-top: 35px; }

.woocommerce-page .woocommerce-error li { padding: 0; }
.woocommerce-page .woocommerce-message .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-error .button { background-color: transparent; color: #1c63b8; text-transform: capitalize; font-size: 16px; }
.woocommerce-page .woocommerce-message,
.woocommerce-page .woocommerce-info,
.woocommerce-page .woocommerce-error { position: relative; font-size: 16px; font-family: "Poppins", sans-serif; padding: 8px 24px 8px 54px; background-color: #fbf0c8; box-shadow: 5px 5px 0px 0px #f1f1f1; margin: 0 0 22px 0; list-style: none; }

.woocommerce-page .woocommerce-message:before,
.woocommerce-page .woocommerce-info:before,
.woocommerce-page .woocommerce-error:before { color: #777; font-size: 11px; content: "\e912"; font-family: "wprticons"; position: absolute; left: 30px; top: 13px; }

.woocommerce-page .woocommerce-message a,
.woocommerce-page .woocommerce-info a,
.woocommerce-page .woocommerce-error a { text-decoration: underline; padding-right: 10px; }
.woocommerce-page .woocommerce-message { border-color: #35a254; }
.woocommerce-page .woocommerce-error { border-color: #d82f2f; }

/* Widgets
-------------------------------------------------------------- */
.woocommerce-page .product_list_widget li { padding: 0; padding-top: 25px; margin-top: 25px; border-top: 1px solid #eee; }
.woocommerce-page .product_list_widget li:first-child { padding-top: 0; margin-top: 0; border: 0; }
.woocommerce-page .product_list_widget li > a { display: block; }

.woocommerce-page .cart_list li img,
.woocommerce-page .product_list_widget li img { float: left; width: 80px;  margin-left: 0; margin-right: 20px; box-shadow: none; }
.woocommerce-page .product_list_widget .product-title,
.woocommerce-page .widget_recent_reviews .product_list_widget a,
.woocommerce-page .product_list_widget .mini_cart_item a { font-family: "Poppins", sans-serif; letter-spacing: 0.5px; margin-bottom: 4px; display: block; color: #333; font-size: 14px; line-height: 24px; text-transform: uppercase; -webkit-transition: all ease .238s; -moz-transition: all ease .238s; transition: all ease .238s; }

.woocommerce-page .product_list_widget .product-title:hover,
.woocommerce-page .widget_recent_reviews .product_list_widget a:hover,
.woocommerce-page .product_list_widget .mini_cart_item a:hover { color: #1c63b8; }

.woocommerce-page .product_list_widget .quatity,
.woocommerce-page .product_list_widget .amount { font-family: "Poppins", sans-serif; font-size: 16px; }
.woocommerce-page .product_list_widget del .amount { padding-right: 5px; }

.woocommerce-page .product_list_widget .star-rating,
.woocommerce-page .widget_rating_filter .star-rating { width: 85px; height: 17px; background: url(../img/star.png) repeat-x left 0; margin: 0 0 5px 100px; }
.woocommerce-page .product_list_widget .star-rating span,
.woocommerce-page .widget_rating_filter .star-rating span { background: url(../img/star.png) repeat-x left -34px; height: 0; padding-top: 17px; overflow: hidden; float: left; }
.woocommerce-page .widget_rating_filter .star-rating { display: inline-block; margin: 0; }

/* Widget product categories */
.woocommerce-page .widget_product_categories ul li { font-family: "Poppins", sans-serif; position: relative; text-align: right; color: #999; text-transform: uppercase; }
.woocommerce-page .widget_product_categories ul li:before,
.woocommerce-page .widget_product_categories ul li:after { content: ""; display: table-cell; clear: both; }
.woocommerce-page .widget_product_categories ul li a { float: left; color: #777; font-size: 14px; }
.woocommerce-page .widget_product_categories ul li a:hover { color: #1c63b8; }
.woocommerce-page .widget_product_categories ul li { padding: 11px 0; border-top: 1px solid #eee; }
.woocommerce-page .widget_product_categories ul li:first-child { padding-top: 0; border: 0; }

/* Widget shopping cart */
.woocommerce-page .widget_shopping_cart .wc-forward.checkout { margin-left: 10px; }

/* Widget price filter */
.woocommerce-page .widget_price_filter .price_slider { height: 4px; margin-bottom: 25px; margin-top: 46px; background-color: #aeaeae; position: relative; text-align: left; }
.woocommerce-page .widget_price_filter .price_slider_amount { text-align: right; }
.woocommerce-page .widget_price_filter .price_slider_amount .button { font-family: "Poppins", sans-serif; font-size: 13px; letter-spacing: 1px; float: left; color: #fff; background-color: #aeaeae; padding: 0 31px; height: 36px; line-height: 36px; }
.woocommerce-page .widget_price_filter .price_slider_amount .button:hover { opacity: 1; background-color: #1c63b8; border-color: #1c63b8; color: #fff; }
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle { background-color: #fff; top: -4px; position: absolute; z-index: 2; width: 12px; height: 12px; border: 2px solid #1c63b8; border-radius: 50%; cursor: pointer; }
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle:last-child { margin-left: -12px; left: 100%; }
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle:first-child { left: 0; }
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-range { position: absolute; z-index: 1; display: block; border: 0; background-color: #dbdbdb; left: 0%; width: 100%; }
.woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range { top: 0; height: 100%; }
.woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range-min { left: -1px; }
.woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range-max { right: -1px; }
.woocommerce-page .widget_price_filter .price_slider_amount .price_label { background-color: #f0f0f0; color: #999; font-size: 13px; height: 36px; line-height: 36px; margin-left: 120px; text-align: center; }

/* Widget widget_shopping_cart */
.woocommerce-page .widget_shopping_cart ul.cart_list li .remove { display: none; }
.woocommerce-page .widget_shopping_cart ul.cart_list li .quantity { display: block; font-size: 20px; }
.woocommerce-page .widget_shopping_cart .total { font-size: 18px; color: #333; text-transform: uppercase; padding-top: 20px; margin: 20px 0 12px; font-family: "Poppins", sans-serif; border-top: 1px solid #e4e4e4; text-align: center; }
.woocommerce-page .widget_shopping_cart .wc-forward { border: 1px solid #1c63b8; background-color: transparent; color: #333; padding: 8px 30px;  }
.woocommerce-page .widget_shopping_cart .wc-forward.checkout { background-color: #333; color: #fff; border-color: #333; }
.woocommerce-page .widget_shopping_cart .wc-forward:hover,
.woocommerce-page .widget_shopping_cart .wc-forward.checkout:hover { background-color: #1c63b8; border-color: #1c63b8; color: #fff; }

/* Widget widget_product_search */
.woocommerce-page .widget.widget_product_search .woocommerce-product-search { position: relative; }
.woocommerce-page .widget.widget_product_search .woocommerce-product-search .search-field { background-color: #f9f9f9; padding-right: 48px; margin-bottom: 0; border: 1px solid #f5f5f5; }
.woocommerce-page .widget.widget_product_search .woocommerce-product-search .search-submit { position: absolute; background-color: transparent; top: 9px; right: 6px; width: 30px; height: 30px; text-align: center; text-indent: 999999px; padding: 0; border: 0; overflow: hidden; }
.woocommerce-page .widget.widget_product_search .woocommerce-product-search .search-submit:before { color: #999; content: "\e957"; font-family: "wprticons"; font-size: 14px; width: 30px; height: 30px; line-height: 30px; position: absolute; left: 0; top: 0; text-align: center; text-indent: 0; font-weight: normal; transition: all 0.3s ease 0s; -webkit-transition: all 0.3s ease 0s; -moz-transition: all 0.3s ease 0s; }
.woocommerce-page .widget.widget_product_search .woocommerce-product-search .search-submit:hover:before { color: #333; }

@media only screen and (max-width: 991px) {
	.woocommerce-page.shop-col-4 .products li,
	.woocommerce-page.shop-col-5 .products li { width: 50%; }

	.woocommerce-page .content-woocommerce .woocommerce-result-count { float: none; margin-bottom: 25px; }
	.woocommerce-page .content-woocommerce .woocommerce-ordering { float: none; position: static; }
	.woocommerce-page .content-woocommerce .woocommerce-ordering:before { display: none; }

	.woo-single-post-class .images,
	.woo-single-post-class .summary { width: 100%; float: none; }
	.woo-single-post-class .summary { margin-top: 30px; }

	.woo-single-post-class .related.products .products li { width: 50%; margin-bottom: 20px; }

	.woo-single-post-class .woocommerce-tabs ul { border: 0; }
	.woo-single-post-class .woocommerce-tabs ul li,
	.woo-single-post-class .woocommerce-tabs ul li a { display: block; margin: 0px; }
	.woo-single-post-class .woocommerce-tabs ul li a { border: 1px solid #d9d9d9; margin-bottom: 5px; }
	.woo-single-post-class .woocommerce-tabs ul li.active { top: 0; }
	.woo-single-post-class .woocommerce-tabs ul li.active a { border-bottom: 1px solid #d9d9d9 !important; }

	.woo-single-post-class .woocommerce-tabs .comment-respond { margin-top: 30px; }
	.woo-single-post-class .woocommerce-tabs .panel { padding-top: 30px; }

	.woocommerce-page .shop_table.cart td, .woocommerce-page .shop_table.cart th { padding: 15px; }
	.woocommerce-page .shop_table.cart .product-remove { width: 50px; }
	.woocommerce-page .shop_table.cart .product-thumbnail { width: 100px; }
	.woocommerce-page .shop_table.cart .product-name { width: 150px; }
}
@media only screen and (max-width: 767px) {
	.woocommerce-page.shop-col-2 .products li,
	.woocommerce-page.shop-col-3 .products li,
	.woocommerce-page.shop-col-4 .products li,
	.woocommerce-page.shop-col-5 .products li { width: 100%; }

	.woo-single-post-class .related.products .products li { width: 100%; }
	.woo-single-post-class .related.products .products li:last-child { margin-bottom: 0; }
}